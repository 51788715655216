// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import initializeApp from "../app/initializeApp";

window.Trix = require("trix")

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("trix")
require("@rails/actiontext")

require.context('../images', true);
require("../stylesheets/files.scss");

import bootstrap from "bootstrap";
import "controllers"
import iziToast from "izitoast";

iziToast.settings({
  timeout: 8000,
  position: "topRight"
});

$(document).on('change', '[data-autosubmit="change"]', function(event) {
  if($(this).closest('form').find('input[type="submit"]').length) {
    $(this).closest('form').find('input[type="submit"]').trigger('click');
  } else {
    $(this).closest('form').submit();
  }
});

$(document).on('click', '.js-document-download', function() {
  iziToast.success({
    title: 'Download in corso',
    position: 'topRight'
  });
});

$(document).on('click', '.reset-free-search', function() {
  $('.free-search-field').val('');
  $('.free-search-field').trigger('change')
});

$(document).on('click', '.show-more-documents', function() {
  $(this).closest('.more-documents-wrapper').find('.more-documents').removeClass('d-none');
  $(this).addClass('d-none');
});

$(function() {
  $('#offcanvasRight a').click(function() {
    $('#offcanvasRight .btn-close').trigger('click');
  });
  initializeApp();
});
